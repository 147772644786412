import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { TokenService } from './token.service';
import { MasterService } from './master.service';
import { id } from '@swimlane/ngx-datatable/release/utils';

@Injectable({
  providedIn: 'root'
})
export class FlashcardService {
  url = '';

  reqheadermain = new HttpHeaders({ 'Authorization': 'Bearer ' + this.token.getToken() });

  constructor(
    public http: HttpClient,
    public token: TokenService,
    public masterService: MasterService,
  ) {
    this.url = masterService.url
  }

  masterboard() {
    return this.http.get(this.url + 'getmasterboard', { headers: this.reqheadermain });
  }

  mastergrade() {
    return this.http.get(this.url + 'getmastergrade', { headers: this.reqheadermain });
  }

  mastersubject() {
    return this.http.get(this.url + 'mastersubjects', { headers: this.reqheadermain });
  }

  addLesson(value) {
    return this.http.post(this.url + 'addlesson', value, { headers: this.reqheadermain });
  }

  addFC(value) {
    return this.http.post(this.url + 'addflashcard', value, { headers: this.reqheadermain });
  }

  addQuickfacts(value) {
    return this.http.post(this.url + 'addquickfacts', value, { headers: this.reqheadermain });
  }

  addsampleQuestions(value) {
    return this.http.post(this.url + 'addsamplequestion', value, { headers: this.reqheadermain });
  }

  updateLesson(value) {
    return this.http.post(this.url + 'updatelesson', value, { headers: this.reqheadermain });
  }

  editLesson(id) {
    return this.http.get(this.url + 'editlesson/' + id, { headers: this.reqheadermain });
  }

  // showQuickfacts(id) { 
  //   return this.http.get(this.url + 'showquickfacts/'+id, { headers: this.reqheadermain });
  // }

  showLesson(subject,board,grade) {
    var data = {subject: subject, board:board , grade:grade};
    return this.http.post(this.url+'showlesson',data, { headers:this.reqheadermain } );
  }

  activeInactive(id, status) {
    var data = { id: id, status: status };
    return this.http.post(this.url + '', data, { headers: this.reqheadermain });
  }

  tableSorting(value) {
    return this.http.post(this.url + 'sortinglesson', value, { headers: this.reqheadermain });
  }

  tablesortingflashcards(value) {
    return this.http.post(this.url + 'sortingflashcard', value, { headers: this.reqheadermain });
  }

  tablesortingquickfacts(value) {
    return this.http.post(this.url + 'sortingquickfact', value, { headers: this.reqheadermain });
  }

  tablesortingsamplequestion(value) {
    return this.http.post(this.url + 'sortingsamplequestion', value, { headers: this.reqheadermain });
  }

  tablesortingquiz(value) {
    return this.http.post(this.url + 'sortingquizquestion', value, { headers: this.reqheadermain });
  }

  listFlashCard(lesson_id) {
    var data = { lesson_id: lesson_id };
    return this.http.post(this.url + 'showflashcardtable', data, { headers: this.reqheadermain });
  }

  editFlashCard(id) {
    return this.http.get(this.url + 'editflashcard/' + id, { headers: this.reqheadermain });
  }

  listQuickFacts(lesson_id) {
    var data = { lesson_id: lesson_id };
    return this.http.post(this.url + 'showquickfactstable', data, { headers: this.reqheadermain });
  }

  editQuickFacts(id) {
    return this.http.get(this.url + 'editquickfacts/' + id, { headers: this.reqheadermain });
  }

  updateQuickFacts(value) {
    var data = { id: value.id, message: value.message, lesson_id: value.lesson_id };
    return this.http.post(this.url + 'updatequickfacts', data, { headers: this.reqheadermain });
  }

  listSampleQuestions(lesson_id) {
    var data = { lesson_id: lesson_id };
    return this.http.post(this.url + 'showsamplequestiontable', data, { headers: this.reqheadermain });
  }

  editSampleQuestions(id) {
    return this.http.get(this.url + 'editsamplequestion/' + id, { headers: this.reqheadermain });
  }

  updateSampleQuestions(value) {
    var data = { id: value.id, samplequestions: value.samplequestions, lesson_id: value.lesson_id };
    return this.http.post(this.url + 'updatesamplequestion', data, { headers: this.reqheadermain });
  }

  deleteSampleQuestions(id) {
    var data = { id: id };
    return this.http.post(this.url + 'deleteSamplequestion', data, { headers: this.reqheadermain });
  }

  deleteQuickfacts(id) {
    var data = { id: id };
    return this.http.post(this.url + 'deletequickfact', data, { headers: this.reqheadermain });
  }

  updateFlashcard(value) {
    console.log(value);
    // var data = { id : value.id, title: value.title, description : value.description, mediatype: value.mediatype}
    return this.http.post(this.url + 'updateflashcard', value, { headers: this.reqheadermain });
  }

  deleteFlashCard(id) {
    var data = { id: id };
    return this.http.post(this.url + 'deleteflashcard', data, { headers: this.reqheadermain });
  }

  activeInactiveLesson(id, status) {
    var data = { id: id, status: status };
    return this.http.post(this.url + 'activeinactivelesson', data, { headers: this.reqheadermain });
  }


  mastersubjectList(offset, limit, search, orderdir, orderby) {
    var data = { offset: offset, limit: limit, search: search, orderdir: orderdir, orderby: orderby };
    return this.http.post(this.url + 'masterflashcardsubject', data, { headers: this.reqheadermain });
  }

  addmasterflashcardsubject(data) {
    return this.http.post(this.url + 'addmasterflashcardsubject', data, { headers: this.reqheadermain });
  }

  editMasterflashcardsubject(id) {
    return this.http.get(this.url + 'editmasterflashcardsubject/' + id, { headers: this.reqheadermain });
  }

  updatemasterflashcardsubject(data) {
    return this.http.post(this.url + 'updatemasterflashcardsubject', data, { headers: this.reqheadermain });
  }

  activeinactivemasterflashcardsubject(id, value) {
    var data = { id: id, value: value };
    return this.http.post(this.url + 'activeinactivemasterflashcardsubject', data, { headers: this.reqheadermain });
  }

  addquiz(value) {
    return this.http.post(this.url + 'addquizquestionanswers', value, { headers: this.reqheadermain });
  }

  showquiz(lesson_id) {
    var data = { lesson_id: lesson_id };
    return this.http.post(this.url + 'showquiztable',data, { headers: this.reqheadermain });
  }

  editQuiz(id) {
    var data = { id: id };
    return this.http.get(this.url + 'editquizquestion/' + id, { headers: this.reqheadermain } );
  }

  updateQuiz(value) {
    return this.http.post(this.url + 'updatequizquestion', value, { headers: this.reqheadermain } );
  } 

  deleteQuiz(id) {
    var data = { id: id };
    return this.http.post(this.url + 'deletequizquestion', data , { headers: this.reqheadermain } );
  }

  deleteLesson(id) {
    var data = { id: id };
    return this.http.post(this.url + 'deletelesson', data , { headers: this.reqheadermain } );
  }

}
