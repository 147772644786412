import { Injectable } from '@angular/core';
import { CanActivate,Router, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { Observable } from 'rxjs';
import {AuthService} from './auth.service';


@Injectable({
  providedIn: 'root'
})
export class AppreciationcardGuard implements CanActivate {
  constructor(public _authservice:AuthService,public _router:Router){}
  canActivate(): boolean {
    if(localStorage.getItem('School Admin') || localStorage.getItem('Principal')|| localStorage.getItem('Teacher') || localStorage.getItem('Institute Admin')){
      // console.log("got role");
        return true;
      }else{
        // console.log("not got role");
        this._router.navigate(['/dashboard'])
        return false;
      }
    }
}
