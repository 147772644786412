import { Injectable } from '@angular/core';
import { CanActivate,Router, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { Observable } from 'rxjs';
import {AuthService} from './auth.service';


@Injectable({
  providedIn: 'root'
})
export class SystemadminGuard implements CanActivate {
  constructor(public _authservice:AuthService,public _router:Router){}
  canActivate(): boolean {
    if(localStorage.getItem('System Admin')){
      console.log("got role");
     // console.log("1");
        return true;
      }else{
        console.log("not got role");
        this._router.navigate(['/dashboard'])
        return false;
      }
    }
}
