import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { TokenService } from './token.service';
import { MasterService } from './master.service';

@Injectable({
  providedIn: 'root'
})
export class GroupService {

  reqheadermain = new HttpHeaders({'Authorization':'Bearer '+this.token.getToken()});
  url= '';

  constructor(
    public http: HttpClient,
    public token:TokenService,
    public masterService: MasterService
  ) {
    this.url = masterService.url
  }

  groupList(offset, limit, orderby, orderdir, search){
    var data = {offset:offset, limit:limit, orderby:orderby, orderdir:orderdir, search:search}; 
    return this.http.post(this.url+'grouptable',data,{headers:this.reqheadermain}); 
  }

  addGroup(value){
    return this.http.post(this.url+'addgroup',value,{headers:this.reqheadermain});
  }

  editGroup(id){
    return this.http.get(this.url+'editgroup/'+id,{headers:this.reqheadermain});
  }

  updateGroup(value){
    return this.http.post(this.url+'updategroup',value,{headers:this.reqheadermain});
  }

  deleteGroup(id){
    return this.http.get(this.url+'deletegroup/'+id,{headers:this.reqheadermain});
  }

  automaticgroup(){
    return this.http.post(this.url+'automaticgroup',{headers:this.reqheadermain});
  }

  activeinactivegroup(id,value){
    var data = {id: id,status:value};
    return this.http.post(this.url+'activeinactivegroup',data,{headers:this.reqheadermain});
  }
}
