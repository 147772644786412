import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { TokenService } from './token.service';
import { MasterService } from './master.service';
// import { timingSafeEqual } from 'crypto';

@Injectable({
  providedIn: 'root'
})
export class AppreciationService {
  reqheadermain = new HttpHeaders({'Authorization':'Bearer '+this.token.getToken()});
  url= '';

  constructor(
    public http: HttpClient,
    public token:TokenService,
    public masterService: MasterService
  ) {
    this.url = masterService.url
   }

  appreciationCardList(offset,limit,search,orderdir,orderby){
    var data = {offset: offset, limit:limit , search:search, orderdir: orderdir , orderby:orderby};
    // return this.http.post(this.url+ 'getschool' , {headers:this.reqheadermain, params: data});
    return this.http.post(this.url+'appreciationcardlist',data,{headers:this.reqheadermain});
  }

  getTeachers(){
    return this.http.get(this.url+ 'getteacher',{headers:this.reqheadermain});
  }

  getGroups(teacherid?){
    // console.log(teacherid);
    const id = teacherid ? teacherid : ''; 
    return this.http.get(this.url+'getappreciationcardgroup/'+id,{headers:this.reqheadermain});
  }

  getStudents(groupid,assignId){
    var val = { groupid, assignId }
    return this.http.post(this.url+'getappreciationstudent',val,{headers:this.reqheadermain});
  }

  addAppreciationCard(value){
    // console.log("ye hai "+value);
    return this.http.post(this.url+'addappreciationcard',value,{headers:this.reqheadermain});
  }

  assignAppreciation(value){
    // console.log(value);
    return this.http.post(this.url+'assignappreciationcard',value,{headers:this.reqheadermain});
  }

  editAppreciationCard(id){
    return this.http.get(this.url+'editappreciationcard/'+id,{headers:this.reqheadermain});
  }

  updateAppreciationCard(value){
    return this.http.post(this.url+'updateappreciationcard',value,{headers:this.reqheadermain});
  }

  deleteAppreciationCard(ids){
    return this.http.post(this.url+'delappreciationcard',ids,{headers:this.reqheadermain});
  }

  activeInactive(id,status){
    var data = { id, status };
    return this.http.post(this.url+'activeinactiveappreciation',data,{headers:this.reqheadermain});
  }
}